<template>
  <b-card class="full-height-card">
    <b-card-header>
      <b-card-title>{{ $t('offlineEmmasTimeline') }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <chartjs-component-line-chart
        :key="id"
        :data="chartData"
        :options="chartOptions"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardBody, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { $themeColors, $chartColors } from '@themeConfig'
import ChartjsComponentLineChart from '../../charts/ChartjsComponentLineChart.vue'

export default {
  name: 'OfflineEmmasGraph',
  components: {
    ChartjsComponentLineChart,
    BCardBody,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  props: {
    payload: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: 0,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
          borderColor: $themeColors.dark,
          borderWidth: 1,
          callbacks: {
            label(context) {
              return `${context.value}% Emmas offline`
            },
          },
        },
        layout: {
          padding: {
            top: -25,
            bottom: -25,
            left: 0,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
              ticks: {
                fontColor: $chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Offline Emmas in %',
              },
              ticks: {
                stepSize: 10,
                min: 0,
                max: 100,
                fontColor: $chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      }
    },
    chartData() {
      const label = []
      const datasets = []

      if (this.payload[0] !== undefined) {
        for (let i = 0; i < this.payload[0].dataset.length; i += 1) {
          label.push(this.formatTimestamp(this.payload[0].dataset[i][0]))
        }

        for (let i = 0; i < this.payload.length; i += 1) {
          let color
          if (this.payload.length === 1) {
            color = '#F97794'
          } else {
            color = this.calculateColor(i / (this.payload.length - 1))
          }
          const data = []

          for (let j = 0; j < this.payload[i].dataset.length; j += 1) {
            const percentage = this.payload[i].dataset[j][1] / this.payload[i].totalEmmas
            data.push(Math.round(percentage * 100))
          }

          datasets.push({
            label: this.payload[i].name,
            data,
            borderColor: color,
            lineTension: 0.3,
            pointStyle: 'circle',
            backgroundColor: color,
            fill: false,
            pointRadius: 3,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: color,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: $chartColors.tooltipShadow,
          })
        }
      }

      return {
        labels: label,
        datasets,
      }
    },

  },
  mounted() {
    this.id += 1
  },
  methods: {
    calculateColor(percentage) {
      let color1
      let color2
      let shiftedPercentage
      if (percentage < 0.5) {
        shiftedPercentage = percentage * 2
        color1 = '#55DD92'
        color2 = '#ffc085'
      } else {
        shiftedPercentage = (percentage - 0.5) * 2
        color1 = '#ffc085'
        color2 = '#F97794'
      }

      const hex = color => {
        const colorString = color.toString(16)
        return colorString.length === 1 ? `0${colorString}` : colorString
      }
      const r = Math.ceil(
        parseInt(color2.substring(1, 3), 16) * shiftedPercentage
          + parseInt(color1.substring(1, 3), 16) * (1 - shiftedPercentage),
      )
      const g = Math.ceil(
        parseInt(color2.substring(3, 5), 16) * shiftedPercentage
          + parseInt(color1.substring(3, 5), 16) * (1 - shiftedPercentage),
      )
      const b = Math.ceil(
        parseInt(color2.substring(5, 7), 16) * shiftedPercentage
          + parseInt(color1.substring(5, 7), 16) * (1 - shiftedPercentage),
      )

      return `#${hex(r)}${hex(g)}${hex(b)}`
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp)
      const day = new Date(date).getDate()
      const month = new Date(date).getMonth() + 1
      return `${day.toString()}.${month.toString()}`
    },
  },
}
</script>

<style scoped>

.full-height-card {
  height: calc(100% - 2rem);
}

</style>
