<template>
  <statistic-card-horizontal
    :style="(alarmAmount === 0) ? '' : 'box-shadow: 0px 0px 10px 7px #F97794'"
    class="full-height-card"
    icon="AlertOctagonIcon"
    :color="(alarmAmount === 0) ? 'success' : 'danger'"
    :statistic="(alarmAmount === 1) ? $t('oneAlarmInLast24Hours') : $t('alarmsInLast24Hours', {amount: alarmAmount})"
    statistic-title="Health Check"
  />
</template>
<script>
import statisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  name: 'AlarmStatisticCard',
  components: {
    statisticCardHorizontal,
  },
  props: {
    alarmAmount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },

}
</script>

<style scoped>

.full-height-card {
  height: calc(100% - 2rem);
}

</style>
