<template>
  <b-modal

    id="edit-cluster-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="$t('editHouse')"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <b-form>
      <b-form-group>
        <b-card>
          <h6>{{ $t('organisation') }}</h6>
          <v-select
            v-model="selectedOrganization"
            :disabled="selectableOrganizations.length <= 1"
            :options="selectableOrganizations"
            :get-option-label="(option) => option.name"
          />
        </b-card>
        <b-card>
          <h6>{{ $t('county') }}</h6>
          <v-select
            v-model="selectedSuborganization"
            :disabled="selectableSuborganizations.length <= 1"
            :options="selectableSuborganizations"
            :get-option-label="(option) => option.name"
          />
        </b-card>
        <b-card>
          <label for="name"> <h6>{{ $t('name') }}</h6> </label>
          <b-form-input
            id="name"
            v-model="name"
            class="mb-1"
          />
          <label for="address"> <h6>{{ $t('address') }}</h6> </label>
          <b-form-input
            id="address"
            v-model="address"
            class="mb-1"
          />
          <label for="city"> <h6>{{ $t('city') }}</h6> </label>
          <b-form-input
            id="city"
            v-model="city"
            class="mb-1"
          />
          <label for="postalcode"> <h6>{{ $t('postleitzahl') }}</h6> </label>
          <b-form-input
            id="postalcode"
            v-model="postalcode"
            class="mb-1"
          />
          <label for="country"> <h6>{{ $t('country') }}</h6> </label>
          <b-form-input
            id="country"
            v-model="country"
            class="mb-1"
          />
        </b-card>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BCard,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddClusterModal',
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    vSelect,
  },
  props: {
    editCluster: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOrganization: undefined,
      selectedSuborganization: undefined,
      name: '',
      address: '',
      city: '',
      postalcode: '',
      country: '',
    }
  },
  computed: {
    selectableOrganizations() {
      return this.$store.state.organizations.organizations
    },
    selectableSuborganizations() {
      if (this.selectedOrganization !== undefined && this.selectedOrganization !== null) {
        const suborganizationList = this.$store.getters['suborganizations/getSuborganizationByOrganizationId'](parseInt(this.selectedOrganization.id, 10))
        if (suborganizationList.length > 1) {
          return suborganizationList
        }
        [this.selectedSuborganization] = suborganizationList
        return suborganizationList
      }
      return []
    },
    newCluster() {
      return {
        id: this.editCluster.id,
        Suborganization: { id: this.selectedSuborganization.id, Organization: { id: this.selectedOrganization.id } },
        name: this.name,
        address: this.address,
        city: this.city,
        postalcode: this.postalcode,
        country: this.country,
      }
    },
  },
  watch: {
    selectedOrganization(newVal, oldVal) {
      if (oldVal !== undefined && newVal !== undefined) {
        this.selectedSuborganization = undefined
      }
    },
  },
  mounted() {
  },
  methods: {
    checkValidity() {
      if (this.selectedOrganization !== undefined && this.selectedSuborganization !== undefined
          && this.name !== '' && this.address !== '' && this.city !== '' && this.postalcode !== '') {
        return true
      }
      return false
    },
    resetModal() {
      this.selectedOrganization = undefined
      this.selectedSuborganization = undefined
      this.name = ''
      this.address = ''
      this.city = ''
      this.postalcode = ''
      this.country = ''
    },
    loadData() {
      this.selectedOrganization = this.$store.getters['organizations/getOrgById'](parseInt(this.editCluster.Suborganization.Organization.id, 10))
      this.selectedSuborganization = this.$store.getters['suborganizations/getSuborganizationById'](parseInt(this.editCluster.Suborganization.id, 10))
      this.name = this.editCluster.name
      this.address = this.editCluster.address
      this.city = this.editCluster.city
      this.postalcode = this.editCluster.postalcode
      this.country = this.editCluster.country
    },
    handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.checkValidity()) {
        let toastText = ''
        this.$store.dispatch('clusters/editCluster', this.newCluster)
        toastText = `Haus ${this.name} erfolgreich bearbeitet`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$nextTick(() => {
          this.$bvModal.hide('edit-cluster-modal')
        })
        this.resetModal()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
