<template>
  <b-modal

    id="add-localGroup-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="$t('addFlat')"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <b-form>
      <b-form-group>
        <b-card>
          <h6>{{ $t('organisation') }}</h6>
          <v-select
            v-model="selectedOrganization"
            :disabled="selectableOrganizations.length <= 1"
            :options="selectableOrganizations"
            :get-option-label="(option) => option.name"
          />
        </b-card>
        <b-card>
          <h6>{{ $t('county') }}</h6>
          <v-select
            v-model="selectedSuborganization"
            :disabled="selectableSuborganizations.length <= 1"
            :options="selectableSuborganizations"
            :get-option-label="(option) => option.name"
          />
        </b-card>
        <b-card>
          <h6>{{ $t('house') }}</h6>
          <v-select
            v-model="selectedCluster"
            :disabled="selectableClusters.length <= 1"
            :options="selectableClusters"
            :get-option-label="(option) => option.name"
          />
        </b-card>
        <b-card>
          <label for="name"> <h6>{{ $t('name') }}</h6> </label>
          <b-form-input
            id="name"
            v-model="name"
            class="mb-1"
          />
          <label for="address"> <h6>{{ $t('address') }}</h6> </label>
          <b-form-input
            id="address"
            v-model="address"
            class="mb-1"
          />
        </b-card>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BCard,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddClusterModal',
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    vSelect,
  },
  props: {
  },
  data() {
    return {
      selectedOrganization: undefined,
      selectedSuborganization: undefined,
      selectedCluster: undefined,
      name: '',
      address: '',
    }
  },
  computed: {
    selectableOrganizations() {
      return this.$store.state.organizations.organizations
    },
    selectableSuborganizations() {
      if (this.selectedOrganization !== undefined && this.selectedOrganization !== null) {
        const suborganizationList = this.$store.getters['suborganizations/getSuborganizationByOrganizationId'](parseInt(this.selectedOrganization.id, 10))
        if (suborganizationList.length > 1) {
          return suborganizationList
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        [this.selectedSuborganization] = suborganizationList
        return suborganizationList
      }
      return []
    },
    selectableClusters() {
      if (this.selectedSuborganization !== undefined && this.selectedSuborganization !== null) {
        const clusterList = this.$store.getters['clusters/getClusterBySuborganizationId'](parseInt(this.selectedSuborganization.id, 10))
        if (clusterList.length > 1) {
          return clusterList
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        [this.selectedCluster] = clusterList
        return clusterList
      }
      return []
    },
    newLocalGroup() {
      return {
        Cluster: { id: this.selectedCluster.id, Suborganization: { id: this.selectedSuborganization.id, Organization: { id: this.selectedOrganization.id } } },
        name: this.name,
        address: this.address,
        city: this.city,
        postalcode: this.postalcode,
        country: this.country,
      }
    },
  },
  watch: {
    selectedOrganization(newVal, oldVal) {
      if (oldVal !== undefined && newVal !== undefined) {
        this.selectedSuborganization = undefined
      }
    },
  },
  mounted() {
  },
  methods: {
    checkValidity() {
      if (this.selectedOrganization !== undefined && this.selectedSuborganization !== undefined && this.selectedCluster !== undefined
          && this.name !== '' && this.address !== '') {
        return true
      }
      return false
    },
    resetModal() {
      this.selectedOrganization = undefined
      this.selectedSuborganization = undefined
      this.selectedCluster = undefined
      this.name = ''
      this.address = ''
      this.city = ''
      this.postalcode = ''
      this.country = ''
    },
    loadData() {
      if (this.selectableOrganizations.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedOrganization = this.selectableOrganizations[0]
      }
      if (this.selectableSuborganizations.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedSuborganization = this.selectableSuborganizations[0]
      }
      if (this.selectableClusters.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedCluster = this.selectableClusters[0]
      }
    },
    handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.checkValidity()) {
        let toastText = ''
        this.$store.dispatch('localGroups/addLocalGroup', this.newLocalGroup)
        toastText = `Wohnung ${this.name} erfolgreich erstellt`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$nextTick(() => {
          this.$bvModal.hide('add-localGroup-modal')
        })
        this.resetModal()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
