<template>
  <b-modal

    id="edit-suborganization-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="$t('editCounty')"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <b-form>
      <b-form-group>
        <b-card>
          <h6>{{ $t('organisation') }}</h6>
          <v-select
            v-model="selectedOrganization"
            :disabled="selectableOrganizations.length <= 1"
            :options="selectableOrganizations"
            :get-option-label="(option) => option.name"
          />
        </b-card>
        <b-card>
          <label for="name"> <h6>{{ $t('name') }}</h6> </label>
          <b-form-input
            id="name"
            v-model="name"
            class="mb-1"
          />
          <label for="country"> <h6>{{ $t('country') }}</h6> </label>
          <b-form-input
            id="country"
            v-model="country"
            class="mb-1"
          />
        </b-card>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BCard,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddSuborganizationModal',
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    vSelect,
  },
  props: {
    editSuborganization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOrganization: undefined,
      name: '',
      country: '',
    }
  },
  computed: {
    newSuborganization() {
      return {
        id: this.editSuborganization.id,
        organizationID: this.selectedOrganization.id,
        name: this.name,
        country: this.country,
      }
    },
    selectableOrganizations() {
      return this.$store.state.organizations.organizations
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    checkValidity() {
      if (this.selectedOrganization !== undefined && this.name !== '' && this.country !== '') {
        return true
      }
      return false
    },
    resetModal() {
      this.selectedOrganization = undefined
      this.name = ''
      this.country = ''
    },
    loadData() {
      this.selectedOrganization = this.$store.getters['organizations/getOrgById'](parseInt(this.editSuborganization.organizationID, 10))
      this.name = this.editSuborganization.name
      this.country = this.editSuborganization.country
    },
    handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.checkValidity()) {
        let toastText = ''
        this.$store.dispatch('suborganizations/editSuborganization', this.newSuborganization)
        toastText = `Land ${this.name} erfolgreich bearbeitet`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$nextTick(() => {
          this.$bvModal.hide('edit-suborganization-modal')
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
