<template>
  <statistic-card-horizontal
    :style="(offlineEmmas === 0) ? '' : 'box-shadow: 0px 0px 10px 7px #F97794'"
    class="full-height-card"
    icon="ActivityIcon"
    :color="(offlineEmmas === 0) ? 'success' : 'danger'"
    :statistic="offlineEmmasString"
    statistic-title="Health Check"
  />
</template>

<script>
import statisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  name: 'OfflineEmmasCard',
  components: {
    statisticCardHorizontal,
  },
  props: {
    offlineEmmas: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    offlineEmmasString() {
      switch (this.offlineEmmas) {
        case 0:
          return this.$t('allEmmasOnline')
        case 1:
          return this.$t('oneEmmaOffline')
        default:
          return this.$t('amountEmmasOffline', { amount: this.offlineEmmas })
      }
    },
  },

}
</script>

<style scoped>

.full-height-card {
  height: calc(100% - 2rem);
}

</style>
