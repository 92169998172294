<template>
  <div>
    <b-row
      :key="options.length"
    >
      <b-col
        v-for="option in options"
        :key="option.id"
        cols="12"
        lg="6"
        :xl="(options.length < 3) ? '6' : '4'"
      >
        <b-card
          no-body
          class="full-height-card"
        >
          <b-row
            class="pt-2 pl-2 pr-2"
            style="height: 100%"
          >
            <b-col cols="9">
              <h2>
                {{ option.name }}
              </h2>
            </b-col>
            <b-col cols="3">
              <b-dropdown
                v-if="scope !== 'organization'"
                variant="dark"
                style="width: 100%"
                class="p-0"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="EditIcon"
                  />
                </template>
                <b-dropdown-item
                  v-if="scope === 'suborganization'"
                  v-b-modal.edit-suborganization-modal
                  @click="selected = option"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-50"
                  />
                  {{ $t('edit') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="scope === 'cluster'"
                  v-b-modal.edit-cluster-modal
                  @click="selected = option"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-50"
                  />
                  {{ $t('edit') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="scope === 'localGroup'"
                  v-b-modal.edit-localGroup-modal
                  @click="selected = option"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-50"
                  />
                  {{ $t('edit') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.confirm-delete-modal
                  variant="danger"
                  @click="selected = option"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  {{ $t('delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row
            v-if="scope === 'suborganization'"
            class="pl-2 pr-2"
          >
            <b-col>
              {{ getOrg(option.organizationID).name }}
            </b-col>
          </b-row>
          <b-row
            v-if="scope === 'cluster'"
            class="pl-2 pr-2"
          >
            <b-col cols="12">
              {{ getOrg(option.Suborganization.Organization.id).name }}
            </b-col>
            <b-col cols="12">
              {{ getSuborganization(option.Suborganization.id).name }}
            </b-col>
          </b-row>
          <b-row
            v-if="scope === 'localGroup'"
            class="pl-2 pr-2"
          >
            <b-col cols="12">
              {{ getOrg(option.Cluster.Suborganization.Organization.id).name }}
            </b-col>
            <b-col cols="12">
              {{ getSuborganization(option.Cluster.Suborganization.id).name }}
            </b-col>
            <b-col cols="12">
              {{ getCluster(option.Cluster.id).name }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="p-2">
                <b-button
                  style="width: 100%;"
                  variant="primary"
                  @click="$router.push('/dashboard/' + scope + '?id=' + option.id)"
                >
                  {{ $t('toDashboard') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="scope === 'suborganization'">
      <b-col cols="12">
        <b-card>
          <b-button
            v-b-modal.add-suborganization-modal
            block
            variant="secondary"
          >
            <feather-icon
              class="mr-50"
              icon="PlusSquareIcon"
            />
            {{ $t('addCounty') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="scope === 'cluster'">
      <b-col
        key="add-new-cluster"
        cols="12"
      >
        <b-card>
          <b-button
            v-b-modal.add-cluster-modal
            block
            variant="secondary"
          >
            <feather-icon
              class="mr-50"
              icon="PlusSquareIcon"
            />
            {{ $t('addHouse') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="scope === 'localGroup'">
      <b-col
        key="add-new-suborganization"
        cols="12"
      >
        <b-card>
          <b-button
            v-b-modal.add-localGroup-modal
            block
            variant="secondary"
          >
            <feather-icon
              class="mr-50"
              icon="PlusSquareIcon"
            />
            {{ $t('addFlat') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <add-suborganization-modal
      v-if="scope === 'suborganization'"
    />
    <add-cluster-modal
      v-if="scope === 'cluster'"
    />
    <add-localGroup-modal
      v-if="scope === 'localGroup'"
    />
    <edit-suborganization-modal
      v-if="scope === 'suborganization'"
      :edit-suborganization="selected"
    />
    <edit-cluster-modal
      v-if="scope === 'cluster'"
      :edit-cluster="selected"
    />
    <edit-localGroup-modal
      v-if="scope === 'localGroup'"
      :edit-local-group="selected"
    />
    <b-modal
      id="confirm-delete-modal"
      :no-close-on-backdrop="true"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      ok-title="Löschen"
      :cancel-title="$t('cancel')"
      centered
      :title="$t('confirmDelete')"
      @ok="handleDelete"
    >
      {{ $t('areYouSureThatYou') }} <b>{{ selected.name }}</b> {{ $t('wantToDelete') }}
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BModal, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditSuborganizationModal from '@/components/forms/EditSuborganizationModal.vue'
import AddSuborganizationModal from '@/components/forms/AddSuborganizationModal.vue'
import EditLocalGroupModal from '@/components/forms/EditLocalGroupModal.vue'
import AddLocalGroupModal from '@/components/forms/AddLocalGroupModal.vue'
import EditClusterModal from '@/components/forms/EditClusterModal.vue'
import AddClusterModal from '@/components/forms/AddClusterModal.vue'

export default {
  name: 'SelectionCards',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    AddSuborganizationModal,
    AddClusterModal,
    AddLocalGroupModal,
    EditClusterModal,
    EditSuborganizationModal,
    EditLocalGroupModal,
    BDropdown,
    BDropdownItem,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected: {},
    }
  },
  methods: {
    getOrg(id) {
      return this.$store.getters['organizations/getOrgById'](Number.parseInt(id, 10))
    },
    getSuborganization(id) {
      return this.$store.getters['suborganizations/getSuborganizationById'](Number.parseInt(id, 10))
    },
    getCluster(id) {
      return this.$store.getters['clusters/getClusterById'](Number.parseInt(id, 10))
    },
    async handleDelete() {
      try {
        if (this.scope === 'suborganization') {
          await this.$store.dispatch('suborganizations/removeSuborganization', this.selected)
        }
        if (this.scope === 'cluster') {
          await this.$store.dispatch('clusters/removeCluster', this.selected)
        }
        if (this.scope === 'localGroup') {
          await this.$store.dispatch('localGroups/removeLocalGroup', this.selected)
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.selected.name} erfolgreich gelöscht!`,
            icon: 'EditIcon',
            variant: 'success',
          },

        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.selected.name} konnte nicht gelöscht werden!`,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        console.error(e)
      }
    },
  },
}
</script>

<style scoped>

.full-height-card {
  height: calc(100% - 2rem);
}

</style>
