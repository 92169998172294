<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('networkDistribution') }}</b-card-title>
      <b-dropdown
        :text="dropdownTitle"
        variant="transparent"
        class="chart-dropdown"
        right
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item @click="selected = 'all'">
          {{ $t('allEmmas') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="onlineEmmas.length !== 0"
          @click="selected = 'online'"
        >
          {{ $t('onlineEmmas') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="offlineEmmas.length !== 0"
          @click="selected = 'offline'"
        >
          {{ $t('offlineEmmas') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="donut"
        height="300"
        class="my-1"
        :options="chartOptions"
        :series="series"
      />

      <!-- chart info -->
      <div
        v-for="(data,index) in chartInfo"
        :key="data.name"
        class="d-flex justify-content-between"
        :class="index === chartInfo.length - 1 ? 'mb-0':'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            :icon="data.icon"
            size="16"
            :class="data.iconColor"
          />
          <span class="font-weight-bolder ml-75 mr-25">{{ data.name }}</span><span>- {{ data.usage }}%</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'ActiveNetworkTypeGraph',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  props: {
    emmas: {
      type: Array,
      required: true,
    },
    onlineEmmas: {
      type: Array,
      required: true,
    },
    offlineEmmas: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selected: 'all',
    }
  },
  computed: {
    labels() {
      return [this.$t('gsm'), this.$t('wifi'), this.$t('lan'), this.$t('unknown')]
    },
    dropdownTitle() {
      if (this.selected === 'all') {
        return this.$t('allEmmas')
      } if (this.selected === 'online') {
        return this.$t('onlineEmmas')
      } if (this.selected === 'offline') {
        return this.$t('offlineEmmas')
      }
      return this.$t('allEmmas')
    },
    selectedEmmas() {
      if (this.selected === 'all') {
        return this.emmas
      } if (this.selected === 'online') {
        return this.onlineEmmas
      } if (this.selected === 'offline') {
        return this.offlineEmmas
      }
      return this.emmas
    },
    gsmEmmas() {
      return this.selectedEmmas.filter(emma => emma.activeNetworkInterface === 'ppp0')
    },
    wifiEmmas() {
      return this.selectedEmmas.filter(emma => emma.activeNetworkInterface === 'wlan0')
    },
    lanEmmas() {
      return this.selectedEmmas.filter(emma => emma.activeNetworkInterface === 'eth0')
    },
    unknownEmmas() {
      return this.selectedEmmas.filter(emma => emma.activeNetworkInterface === undefined)
    },
    series() {
      return [this.gsmEmmas.length, this.wifiEmmas.length, this.lanEmmas.length, this.unknownEmmas.length]
    },
    chartInfo() {
      const amountOfEmmas = this.selectedEmmas.length

      return [
        {
          icon: 'RadioIcon',
          name: this.$t('gsm'),
          iconColor: 'text-primary',
          usage: Math.round((this.gsmEmmas.length / amountOfEmmas) * 100),
        },
        {
          icon: 'WifiIcon',
          name: this.$t('wifi'),
          iconColor: 'text-info',
          usage: Math.round((this.wifiEmmas.length / amountOfEmmas) * 100),
        },
        {
          icon: 'BarChartIcon',
          name: this.$t('lan'),
          iconColor: 'text-dark',
          usage: Math.round((this.lanEmmas.length / amountOfEmmas) * 100),
        },
        {
          icon: 'LoaderIcon',
          name: this.$t('networkStatusUnknown'),
          iconColor: 'text-danger',
          usage: Math.round((this.unknownEmmas.length / amountOfEmmas) * 100),
        },
      ]
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: this.labels,
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 2,
            color: '#000',
            opacity: 1,
          },
        },
        legend: { show: false },
        stroke: { width: 0 },
        colors: [$themeColors.primary, $themeColors.info, $themeColors.dark, $themeColors.danger],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter: val => {
                    if (val === '1') {
                      return this.$t('oneDevice')
                    }
                    return this.$t('amountDevices', { amount: val })
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: '',
                  formatter() {
                    return ''
                  },
                },
              },
            },
          },
        },
      }
    },
  },
  mounted() {
  },
  methods: {
    test() {
      return 'test'
    },
  },
}
</script>
