<template>
  <b-card class="full-height-card">
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <chartjs-component-bar-chart
        :key="id"
        :data="chartData"
        :options="chartOptions"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardBody, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { $themeColors, $chartColors } from '@themeConfig'
import ChartjsComponentBarChart from '../../charts/ChartjsComponentBarChart.vue'

export default {
  name: 'OfflineEmmasGraph',
  components: {
    ChartjsComponentBarChart,
    BCardBody,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  props: {
    payload: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      id: 0,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    chartOptions() {
      if (this.scope === 'organization') {
        return {
          animation: false,
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: $chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label(context) {
                return `${context.value}% Emmas offline`
              },
            },
          },
          interaction: {
            mode: 'nearest',
          },
          onClick(event) {
            if (this.getElementAtEvent(event)[0]) {
              // eslint-disable-next-line no-underscore-dangle
              const index = this.getElementAtEvent(event)[0]._index
              // eslint-disable-next-line no-underscore-dangle
              const vm = document.querySelector('#app').__vue__
              const suborganizationID = vm.$store.getters['suborganizations/getSuborganizationByName'](this.data.labels[index]).id
              vm.$router.push(`/dashboard/suborganization?id=${suborganizationID}`)
            }
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: $chartColors.grid_line_color,
                  zeroLineColor: $chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: $chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: $chartColors.grid_line_color,
                  zeroLineColor: $chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Offline Emmas in %',
                },
                ticks: {
                  stepSize: 10,
                  min: 0,
                  max: 100,
                  fontColor: $chartColors.labelColor,
                },
              },
            ],
          },
        }
      }
      if (this.scope === 'suborganization') {
        return {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: $chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label(context) {
                return `${context.value}% Emmas offline`
              },
            },
          },
          interaction: {
            mode: 'nearest',
          },
          onClick(event) {
            if (this.getElementAtEvent(event)[0]) {
              // eslint-disable-next-line no-underscore-dangle
              const index = this.getElementAtEvent(event)[0]._index
              // eslint-disable-next-line no-underscore-dangle
              const vm = document.querySelector('#app').__vue__
              const suborganizationID = vm.$store.getters['clusters/getClusterByName'](this.data.labels[index]).id
              vm.$router.push(`/dashboard/cluster?id=${suborganizationID}`)
            }
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: $chartColors.grid_line_color,
                  zeroLineColor: $chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: $chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: $chartColors.grid_line_color,
                  zeroLineColor: $chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Offline Emmas in %',
                },
                ticks: {
                  stepSize: 10,
                  min: 0,
                  max: 100,
                  fontColor: $chartColors.labelColor,
                },
              },
            ],
          },
        }
      }
      if (this.scope === 'cluster') {
        return {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: $chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label(context) {
                return `${context.value}% Emmas offline`
              },
            },
          },
          interaction: {
            mode: 'nearest',
          },
          onClick(event) {
            if (this.getElementAtEvent(event)[0]) {
              // eslint-disable-next-line no-underscore-dangle
              const index = this.getElementAtEvent(event)[0]._index
              // eslint-disable-next-line no-underscore-dangle
              const vm = document.querySelector('#app').__vue__
              const localGroupID = vm.$store.getters['localGroups/getLocalGroupByName'](this.data.labels[index]).id
              vm.$router.push(`/dashboard/localGroup?id=${localGroupID}`)
            }
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: $chartColors.grid_line_color,
                  zeroLineColor: $chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: $chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: $chartColors.grid_line_color,
                  zeroLineColor: $chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Offline Emmas in %',
                },
                ticks: {
                  stepSize: 10,
                  min: 0,
                  max: 100,
                  fontColor: $chartColors.labelColor,
                },
              },
            ],
          },
        }
      }

      return {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: $chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        interaction: {
          mode: 'nearest',
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: $chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
              scaleLabel: {
                display: true,
                labelString: 'Offline Emmas in %',
              },
              ticks: {
                stepSize: 10,
                min: 0,
                max: 100,
                fontColor: $chartColors.labelColor,
              },
            },
          ],
        },
      }
    },
    chartData() {
      const labels = []

      for (let i = 0; i < this.payload.length; i += 1) {
        labels.push(this.payload[i].name)
      }

      const dataset = []

      for (let i = 0; i < this.payload.length; i += 1) {
        dataset.push(Math.floor((this.payload[i].dataset / this.payload[i].totalEmmas) * 1000) / 10)
      }

      const colors = []
      for (let i = 0; i < dataset.length; i += 1) {
        colors.push(this.calculateColor(dataset[i] / 100))
      }
      return {
        labels,
        datasets: [
          {
            data: dataset,
            borderColor: colors,
            backgroundColor: colors,
          },
        ],
      }
    },

  },
  mounted() {
    this.id += 1
  },
  methods: {
    calculateColor(percentage) {
      let color1
      let color2
      let shiftedPercentage
      if (percentage < 0.5) {
        shiftedPercentage = percentage * 2
        color1 = '#55DD92'
        color2 = '#ffc085'
      } else {
        shiftedPercentage = (percentage - 0.5) * 2
        color1 = '#ffc085'
        color2 = '#F97794'
      }

      const hex = color => {
        const colorString = color.toString(16)
        return colorString.length === 1 ? `0${colorString}` : colorString
      }
      const r = Math.ceil(
        parseInt(color2.substring(1, 3), 16) * shiftedPercentage
          + parseInt(color1.substring(1, 3), 16) * (1 - shiftedPercentage),
      )
      const g = Math.ceil(
        parseInt(color2.substring(3, 5), 16) * shiftedPercentage
          + parseInt(color1.substring(3, 5), 16) * (1 - shiftedPercentage),
      )
      const b = Math.ceil(
        parseInt(color2.substring(5, 7), 16) * shiftedPercentage
          + parseInt(color1.substring(5, 7), 16) * (1 - shiftedPercentage),
      )

      return `#${hex(r)}${hex(g)}${hex(b)}`
    },
  },
}
</script>

<style scoped>

.full-height-card {
  height: calc(100% - 2rem);
}
</style>
